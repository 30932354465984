import Header from '@components/Header/Header';
import { LoginActionModal } from '@components/layout/Login/action/LoginActionModal';
import Login from '@components/layout/Login/Login';
import AcoSEO from '@components/SEO/AcoSEO';
import { AcoCookies, createSSRContinuationContext, requestContext } from '@server/shared/request-continuation';
import { RedirectError, serverSidePropsBuilder } from '@server/shared/server-side-props';
import LoginService, { ConfirmUserResult } from '@service/login.service';
import * as Cookies from 'cookie';
import React from 'react';

type Props = undefined | ({ action: string } & ConfirmUserResult);

function LoginPage(props: Props) {
  return (
    <>
      <AcoSEO nextSEO={{ title: 'Login' }} />
      <Header showSearch={false} showBottomMenu={false} />
      <Login />
      {props.action === 'confirm' && (
        <LoginActionModal {...props} />
      )}
    </>
  );
}

export const getServerSideProps = serverSidePropsBuilder<unknown>()
  .use(createSSRContinuationContext())
  .handle(async (ctx) => {
    if (ctx.query['action'] === 'confirm' && !!ctx.query['token']) {
      const confirmed = await LoginService.confirm(ctx.query['token'] as string);

      return {
        action: 'confirm',
        success: confirmed.success,
        user: confirmed.user,
        error: confirmed.error
      }
    }

    if (ctx.query['token'] || (ctx.query['credential_0'] === 'mac' && ctx.query['credential_1'])) {
      const token = ctx.query['token'] ?? ctx.query['credential_1'];

      if (ctx.query['action'] === 'confirm_login') {
        await LoginService.confirm(ctx.query['token'] as string);
      }

      const login = await LoginService.refreshWithToken(token as string);

      if (login.token) {
        const sessionCookie = requestContext.backendCookies[
          AcoCookies.LEGACY_SESSION
          ] as Cookies.CookieSerializeOptions;

        const actualCookies = Cookies.serialize('token', login.token, { ...sessionCookie, domain: '' });

        const backendCookies = Object.values(requestContext.backendCookies)
          .filter((cookie) => !cookie.fromRequest)
          .map(({ name, value, ...opts }) =>
            Cookies.serialize(name, value, { ...(opts as Cookies.CookieSerializeOptions), domain: '' })
          );

        const finalCookies = [
          ...(Array.isArray(actualCookies) ? actualCookies : [`${actualCookies}`]),
          ...backendCookies,
        ];

        ctx.res.setHeader('Set-Cookie', finalCookies);

        const dest = ctx.query['destination'] ? decodeURIComponent(ctx.query['destination'] as string) : '/';
        throw new RedirectError('login', { permanent: true, destination: dest });
      }
    }

    return {};
  });

export default LoginPage;
