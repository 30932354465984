import Button from '@components/AcoButtons/Button';
import { useFinishRegisterStyles } from '@components/modal/FinishRegister/FinishRegister.styles';
import { Modal } from '@components/modal/Modal';
import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import { ConfirmUserResult } from '@service/login.service';
import clsx from 'clsx';
import React, { useState } from 'react';

export type LoginActionModalProps = { action: string } & ConfirmUserResult;

export const LoginActionModal: React.VFC<LoginActionModalProps> = (props) => {
  const classes = useFinishRegisterStyles();
  const [modalOpen, setModalOpen] = useState(true);

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth='sm'
        fullWidth
        sx={{ marginTop: '126px', '& .MuiDialog-container': { alignItems: 'flex-start' } }}>
        <DialogContent className={classes.content}>
          <Typography variant='h4' color='#26A65B' marginBottom='20px'>
            {!props.error && 'Tu usuario ha sido activado'}
            {props.error && 'No se pudo realizar activacion'}
          </Typography>
          <Typography variant='subtitle1' fontSize='18px' marginBottom='23px'>
            ¡Hola {props.user.name}!{' '}
          </Typography>
          <Box marginBottom='23px'>
            <Typography variant='subtitle1' fontSize='18px' display='inline'>
              {' '}
              Tu usuario con el email{' '}
            </Typography>
            <Typography variant='subtitle1' fontSize='18px' display='inline' word-wrap='break-word' color='#26A65B'>
              {props.user.email}
            </Typography>
            <Typography variant='subtitle1' display='inline' fontSize='18px'>
              {props.error ? ' no' : ''} ha sido activado {props.error ? '' : ' con éxito y ya puedes iniciar sesión'}.{' '}
            </Typography>
            {props.error && (
              <>
                <Typography variant='subtitle1' display='inline' fontSize='18px'>
                  Error: <label dangerouslySetInnerHTML={{__html: props.error as string}}></label>
                </Typography>
              </>
            )}
          </Box>

          <Typography variant='body2' color='#828282'>
            {' '}
            Ante cualquier inconveniente contactarse a contacto@agroconsultasonline.com.ar
          </Typography>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button
            className={clsx('postButton')}
            color='primary'
            variant='contained'
            size='large'
            onClick={() => setModalOpen(false)}>
            Iniciar sesión
          </Button>
        </DialogActions>
      </Modal>
    </>
  );
};
