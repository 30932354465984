import createStyles from '@mui/styles/createStyles';
import { makeResponsiveStyles } from '@providers/Responsive.provider';

export default makeResponsiveStyles(
  (responsive) => (theme) =>
    createStyles({
      mainBox: {
        width: '100%',
      },

      secondaryBox: {
        width: '85%',
        margin: '0 5%',
      },

      boxText: {
        display: 'flex',
      },

      paper: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #DCDCDC',
        boxSizing: 'border-box',
        borderRadius: '8px',

        [responsive.onDesktop]: {
          padding: '29px 27px 28px 29px',
          minWidth: '413px',
          minHeight: '533px',
          marginTop: '10px',
          marginBottom: '10px',
        },
        [responsive.onMobile]: {
          padding: '23px 10px 14px 12px',
          minWidth: '300px',
          width: '70%',
          minHeight: '520px',
          marginTop: '14px',
          marginBottom: '14px',
        },
      },

      boxTop: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },

      buttons: {
        boxSizing: 'border-box',
        borderRadius: '8px',
        minWidth: '170px',
        marginBottom: '18px',
        marginRight: '10px',
        justifyContent: 'flex-start',
        padding: '4px 6px',
        '&:hover': {
          backgroundColor: '#FFFFFF',
        },
        [responsive.onMobile]: {
          minWidth: '120px',
          minHeight: '42px',
          marginBottom: '10px',
          marginRight: '10px',
        },

        '&.facebook': {
          border: `2px solid ${theme.palette.secondary.main}`,
          color: `${theme.palette.secondary.main}`,
        },

        '&.google': {
          border: `2px solid ${theme.palette.error.main}`,
          color: `${theme.palette.error.main}`,
        },
      },

      boxLogin: {
        [responsive.onDesktop]: {
          marginBottom: '24px',
        },
        [responsive.onMobile]: {
          marginBottom: '16px',
        },
      },

      boxConnect: {
        [responsive.onDesktop]: {
          marginBottom: '10px',
        },
        [responsive.onMobile]: {
          marginBottom: '8px',
        },
      },

      textButton: {
        letterSpacing: '0.1px',
        textTransform: 'none',
        [responsive.onDesktop]: {
          marginLeft: '16px',
        },
        [responsive.onMobile]: {
          marginLeft: '3px',
        },
      },

      divider: {
        [responsive.onDesktop]: {
          margin: 'auto 0px',
        },
        [responsive.onMobile]: {
          margin: 'auto 4px',
        },
      },

      boxBottom: {
        marginTop: '24px',
        display: 'flex',
        alignItems: 'center',
        [responsive.onDesktop]: {
          justifyContent: 'start',
        },
        [responsive.onMobile]: {
          minWidth: '260px',
          justifyContent: 'space-around',
        },
      },

      register: {
        letterSpacing: '0.1px',
        textTransform: 'none',
        [responsive.onDesktop]: {
          marginLeft: '18px',
        },
        [responsive.onMobile]: {
          border: '2px solid #26A65B',
          boxSizing: 'border-box',
          borderRadius: '6px',
          padding: '9px 12px',
        },
        '&:hover': {
          backgroundColor: '#FFFFFF',
        },
      },

      icon: {
        minHeight: '22px',
        minWidth: '22px',
        marginRight: '14px',
      },

      socialIcon: {
        [responsive.onMobile]: {
          width: '33px',
          height: '33px',
        },
      },
    })
);
