import { RegisterProvider } from '@components/layout/Register/context/Register.context';
import { RegisterUserProfileForm } from '@components/layout/Register/RegisterUserProfileForm';
import { Box } from '@mui/material';
import { OnDesktop, OnMobile } from '@providers/Responsive.provider';
import React, { useState } from 'react';

import { LoginBranding } from './Branding/LoginBranding';
import styles from './Login.styles';
import LoginForm from './LoginForm';

const Login: React.VFC = () => {
  const classes = styles();

  const [showProfileForm, setShowProfileForm] = useState(false);

  return (
    <>
      <OnDesktop>
        <Box display='flex' justifyContent='center' alignItems='flex-start' className={classes.mainBox}>
          <Box display='flex' justifyContent='space-around' alignItems='center' className={classes.secondaryBox}>
            <LoginBranding />
            <RegisterProvider>
              {!showProfileForm && <LoginForm onContinue={() => setShowProfileForm(true)}/>}
              {showProfileForm && <RegisterUserProfileForm back={() => setShowProfileForm(false)} />}
            </RegisterProvider>

          </Box>
        </Box>
      </OnDesktop>
      <OnMobile>
        <Box display='flex' justifyContent='center' alignItems='center'>
          <RegisterProvider>
            {!showProfileForm && <LoginForm onContinue={() => setShowProfileForm(true)}/>}
            {showProfileForm && <RegisterUserProfileForm back={() => setShowProfileForm(false)} />}
          </RegisterProvider>
        </Box>
      </OnMobile>
    </>
  );
};

export default Login;
